import React, { useContext, useEffect, useState } from 'react'
import { ITariffPlan, IUserInvoicesList } from '../api/models/responses'
import ApiService from '../api/ApiService';
import { AuthContext } from '../context';
import { spellingRequests } from '../utils/utils';

interface IPopularTp {
    amountOfRequests: Number;
    id: Number;
}
interface IProps {
    tariffPlans: ITariffPlan[];
    setTp: React.Dispatch<React.SetStateAction<Number>>;
    popularTariff: IPopularTp;
    setPopularTariff: React.Dispatch<React.SetStateAction<IPopularTp>>;

}
const PackageSlide = ({ tariffPlans, setTp, popularTariff, setPopularTariff }: IProps) => {

    const [remainRequests, setRemainRequests] = useState<number>()

    const [allRequests, setAllRequests] = useState<number>()

    const token = useContext(AuthContext)

    async function fetchGetUserInfo(token: string) {
        const response = await ApiService.userInfo(token)
        setRemainRequests(response.amountOfRequests)

        const invoicesList = (response.userInvoicesList as IUserInvoicesList[])
        let sumRequests = 0
        invoicesList.forEach((el) => {
            if (el.invoiceState === 'PAYED') {
                sumRequests += el.tariffPlansId.amountOfRequests
            }

         })
        setAllRequests(sumRequests)
    }

    useEffect(() => {
        const currentTp = tariffPlans.filter((el) => el.isPopular)[0]
        setPopularTariff({ amountOfRequests: currentTp?.amountOfRequests, id: currentTp?.id })
        console.log({ amountOfRequests: currentTp?.amountOfRequests, id: currentTp?.id })
    }, [tariffPlans])

    useEffect(() => {

        fetchGetUserInfo(token)
    }, [])

    return (
        <div className="package-wrapper swiper">
            <div className="swiper-wrapper">
                <div className="swiper-slide package-item" id='swiper-slide1'>
                    <div className="package-item-top">
                        <div className="package-item-title">Ваш пакет</div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                    </div>
                    <div className="package-item-bottom">
                        <div className="package-item-left">
                            <div className="package-item-span">Остаток запросов</div>
                            <div className="package-item-value">{remainRequests} {spellingRequests(remainRequests!)} из {allRequests}</div>
                        </div>
                        <div className="btn btn-blue" data-modal="popup-tariff">Увеличить объём</div>
                    </div>
                </div>
                <div className="swiper-slide package-item" id="swiper-slide2">
                    <div className="package-item-top">
                        <div className="package-item-status">Советуем</div>
                        <div className="package-item-title">Популярный тариф</div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                    <div className="package-item-bottom">
                        <div className="package-item-left">
                            <div className="package-item-span">Кол-во запросов</div>
                            <div className="package-item-value">{String(popularTariff.amountOfRequests)} шт</div>
                        </div>
                        <div
                            className="btn btn-blue-border"
                            data-modal="popup-bay"
                            data-bay="data-bay"
                            data-saving="80 000"
                            data-requests="1 000"
                            data-validity="12 месяцев"
                            data-price="310 000 ₽"

                        >Купить
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PackageSlide