import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Call from '../components/popup/Call'
import Bay from '../components/popup/Bay'
import Application from '../components/Application'

function Page4 () {
    return (
    <Application />
    )
}

export default Page4