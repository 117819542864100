import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page1 from './pages/Page1';
import Main from './pages/Main';
import './styles/app.scss';
import { Anchors } from "./utils/anchors";
import { Modal } from "./utils/modal";
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page5 from './pages/Page5';
import Layout from './components/Layout';
import PageNotFound from './pages/PageNotFound';
import useAuth from "./hooks/useAuth";
import ApiService from './api/ApiService'
import { AuthContext } from './context';
import { ITariffPlan } from './api/models/responses';
import Page4 from './pages/Page4';

declare global {
  interface Window {
    initRangeSinge: any;
  }
}
const INACTIVITY_TIMEOUT = 60 * 15 * 1000;

function App() {
  const [isLogin, token, userInfo, client] = useAuth();


  async function fetchValidateUser(token: string) {
    const status = await ApiService.validateUser(token)
  }

  const [tariffPlans, setTariffPlans] = useState<ITariffPlan[]>([])

  async function fetchGetTariffPlans(token: string) {
    const response = await ApiService.tariffPlans(token)
    response.sort((a: ITariffPlan, b: ITariffPlan) => {
      if (a.amountOfRequests > b.amountOfRequests) {
        return 1
      }
      else if (a.amountOfRequests < b.amountOfRequests) {
        return -1
      } else {
        return 0
      }
    })
    response.shift()
    setTariffPlans(response)
    console.log()
  }

  useEffect(() => {
    if (isLogin) {


      console.log('login app.tsx')
      console.log(client)
      fetchGetTariffPlans(token)
      fetchValidateUser(token)

      const inputRange = [...document.querySelectorAll(".range-single")];
      const anchors = new Anchors();
      const modal = new Modal();
      const platePosition = (parent: any) => {
        const plate = parent.querySelector(".package-plate");
        plate.style.setProperty("--left", null);
        const parentPos = {
          x: parent.getBoundingClientRect().x,
          y: parent.getBoundingClientRect().x + parent.clientWidth,
        };
        const platePos = {
          x: plate.getBoundingClientRect().x,
          y: plate.getBoundingClientRect().x + plate.clientWidth,
        };

        if (platePos.x < parentPos.x)
          plate.style.setProperty("--left", parentPos.x - platePos.x + "px");
        if (platePos.y > parentPos.y)
          plate.style.setProperty("--left", parentPos.y - platePos.y + "px");
      };
      document.addEventListener("click", (e) => {
        console.log('start')
        modal.init(e.target);
        anchors.click(e);

        if ((e.target as HTMLElement).closest(".btn")) {

          inputRange.forEach((item) => {

            setTimeout(() => {
              platePosition(item);
            }, 0);
          });
        }
      });
    }

  }, [isLogin])

  if (isLogin) {
    console.log(isLogin, userInfo, token)
  }

  useEffect(() => {
    if (isLogin) {
      let timeoutId: any;

      const resetTimer = () => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          console.log('выходим')
          console.log(client)

          client.login({ prompt: 'login' });

        }, INACTIVITY_TIMEOUT);
      };

      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);

      resetTimer();

      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
      };
    }

  }, [client, isLogin]);

  return (
    <AuthContext.Provider value={token}>
      <BrowserRouter>
        {isLogin &&
          <Routes>
            <Route path='/' element={<Layout userInfo={userInfo} tariffPlans={tariffPlans} client={client} />}>
              <Route path='page2' element={<Page2 />} />
              <Route path='page3' element={<Page3 />} />
              <Route path='page4' element={<Page4 />} />
              <Route path='page5' element={<Page5 />} />
              {/* <Route path='page6' element={<Page6 />} /> */}
              <Route index element={<Page1 />} />
            </Route>
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        }
      </BrowserRouter>
    </AuthContext.Provider>
  );
}
// 
export default App;
