import { FC, useEffect, useRef, useState } from 'react'
import { IUsersReport } from '../api/models/responses'
import { imgStatus, splitPrice } from '../utils/utils'
import { colorStatus } from '../api/models/constants'

const HistoryCard: FC<IUsersReport> = (usersReport) => {
  const card = useRef<HTMLDivElement>(null)

  const [isWatch, setIsWatch] = useState<boolean>(false)

  useEffect(() => {

    const item = card.current!
    const watch = item.querySelector(".history-card-watch")!;
    const body = item.querySelector(".history-card-body")!;
    console.log(watch, body)
    if (watch && !isWatch) {
      setIsWatch(true)
      watch.addEventListener("click", () => {
        if (item.classList.contains("active")) {
          const h = body.clientHeight;
          (body as HTMLElement).style.height = h + "px";

          setTimeout(() => {
            (body as HTMLElement).style.height = '0';
          }, 0);

          (body as HTMLElement).ontransitionend = () => {
            (body as HTMLElement).style.height = '';
            item.classList.remove("active");
          };

          return;
        }

        item.classList.add("active");
        const h = body.clientHeight;
        console.log(h);
        (body as HTMLElement).style.height = '0';
        setTimeout(() => {
          (body as HTMLElement).style.height = h + "px";
        }, 0);

        (body as HTMLElement).ontransitionend = () => {
          (body as HTMLElement).style.height = '';
        };
      });
    }

  }, [usersReport])

  return (
    <div className="history-card" ref={card} >
      {usersReport.report &&
        <div className="history-card-header">
          <div className="history-card-info">
            <div className="history-card-number">
              <div className="history-card-id">{usersReport.report.INN}</div>
              {usersReport.requestState === 'FINISHED' ?
                <div className="history-card-status" >Готово</div>
                : usersReport.requestState === 'FAILED' ?
                  <div className="history-card-status" style={{ 'background': '#B64955' }}>Ошибка</div>
                  : <div className="history-card-status" style={{ 'background': 'rgb(223, 155, 54)' }}>В процессе</div>
              }


            </div>
            <div className="history-card-text">Анализ вероятности взыскания задолжености с юр. лиц</div>
            <div className="history-card-date">от {usersReport.report.CURRENT_DATE}</div>
          </div>
          {usersReport.requestState === 'FINISHED' && <div className="history-card-buttons">
            <div className="history-card-watch">
              <span data-before="Просмотреть отчёт" data-after="Свернуть отчёт">
              </span>
              <svg>
                <use xlinkHref="/sprite.svg#select"></use>
              </svg>
            </div>
            <a className="btn btn-red-border" href={usersReport.reportUrl} target="_blank" download="report.pdf">Скачать отчёт в pdf</a>
          </div>
          }
        </div>
      }
      {usersReport.report && usersReport.requestState === 'FINISHED' &&
        <div className="history-card-body">
          <div className="history-card-body-contant">
            <div className="history-card-title"><span>Проверяемая компания:</span>
              <p>{usersReport.report.INN}, ИНН {usersReport.report.NAME}</p>
            </div>
            <div className="history-card-body-list">
              <div className="history-card-item history-card-category">
                <div className="history-card-category-status" style={{ 'background': colorStatus[Number(usersReport.report.category_ID) - 1] }}>
                  <span>Категория благонадёжности</span>
                  <p>{usersReport.report.CAT_BLAG}</p>
                </div>
                <div className="history-card-category-body">
                  <div className="history-card-category-name">{usersReport.report.CAT_BLAG}</div>
                  <div className="history-card-category-text">{usersReport.report.PDF_CATEGORY_FULL_TEXT}</div>
                  <div className="history-card-category-progress">
                    <div className="history-card-category-progress-line"></div>
                    <div className="history-card-category-progress-first">Высокая<br></br> благонадёжность</div>
                    <div className="history-card-category-progress-last">Низкая <br></br> благонадёжность</div>
                  </div>
                </div>
              </div>
              <div className="history-card-item history-card-forecast">
                <div className="history-card-circle">
                  <div className="history-card-svg">
                    <svg viewBox="0 0 181 181">
                      <circle cx="90.0188" cy="90.0183" r="85" transform="rotate(90.0117 90.0188 90.0183)" strokeWidth="10" stroke="#627184"></circle>
                    </svg>
                    <svg className="custom-circle1" viewBox="0 0 181 181">
                      <circle
                        strokeDasharray={String(534 * Number(usersReport.report.TOO_YEAR) / 100) + ',534'}
                        cx="90.0188"
                        cy="90.0183"
                        r="85"
                        transform="rotate(90.0117 90.0188 90.0183)"
                        strokeWidth="10"
                        stroke="#B64955"
                      ></circle>
                    </svg>
                  </div>
                  <div className="history-card-circle-text"><span>AI-прогноз </span><br></br>риска дефолта контрагента</div>
                  <div className="history-card-circle-value">{usersReport.report.TOO_YEAR}%</div>
                </div>
                <div className="history-card-forecast-body">
                  <p>В блоке «AI-прогноз риска дефолта контрагента» анализируется состояние бухгалтерской и финансовой отчётности (в горизонте 2-х лет) на предмет рисков дефолта:</p>
                  <div className="history-card-forecast-list">
                    <div className="history-card-forecast-item">
                      <span>0%–15%</span> вероятность дефолта низкая
                    </div>
                    <div className="history-card-forecast-item">
                      <span>15%–50%</span> вероятность дефолта ниже средней, не является критичной
                    </div>
                    <div className="history-card-forecast-item">
                      <span>50%–75%</span> вероятность дефолта выше средней, возможны финансовые проблемы
                    </div>
                    <div className="history-card-forecast-item">
                      <span>75%–100%</span> вероятность дефолта высокая
                    </div>
                  </div>
                </div>
              </div>
              <div className="history-card-item">
                <div className="history-card-item-image">
                  <img className="history-card-item-image-line bottom" src="/img/line-bottom.svg" alt="" />
                  <picture>
                    <source media="(min-width: 1920px)" srcSet="/img/line-right-1.svg" />
                    <img className="history-card-item-image-line right" src="/img/line-right-2.svg" alt="" />
                  </picture>
                  <div className="history-card-item-value _one">
                    <div className="history-card-item-value-text"><span>AI-прогноз </span> <br></br> для заключения сделок до года</div>
                  </div>
                  <div className="history-card-item-value _two">
                    <div className="history-card-item-value-text">Допустимая сумма для договора</div>
                    <div className="history-card-item-value-value">{splitPrice(usersReport.report.SUMM)} ₽ </div>
                  </div>
                  <div className="history-card-item-value _three active">
                    <div className="history-card-svg">
                      <svg viewBox="0 0 181 181">
                        <circle cx="90.0188" cy="90.0183" r="85" transform="rotate(90.0117 90.0188 90.0183)" strokeWidth="10" stroke="#B4BECA"></circle>
                      </svg>
                      <svg viewBox="0 0 181 181" className='custom-circle2'>
                        <circle
                          cx="90.0188"
                          cy="90.0183"
                          r="85"
                          strokeDasharray={String(534 * Number(usersReport.report.PERS.replace('%', '')) / 100) + ',534'}
                          transform="rotate(90.0117 90.0188 90.0183)"
                          strokeWidth="10"
                          stroke="#6FA598"
                        ></circle>
                      </svg>
                    </div>
                    <div className="history-card-item-value-text">Вероятность взыскания</div>
                    <div className="history-card-item-value-value">{usersReport.report.PERS}</div>
                  </div>
                </div>
                <div className="history-card-item-text">
                  <p>В блоке «AI-прогноз для заключения сделок до года» анализируется бухгалтерская отчётность, оценка ликвидности активов, а также негативные/позитивные события, которые показывают, как проверяемая компания вела себя со своими кредиторами в настоящем и в прошлом.</p>
                </div>
              </div>
              <div className="history-card-item">
                <div className="history-card-item-image">
                  <img className="history-card-item-image-line bottom" src="/img/line-bottom.svg" alt="" />
                  <picture>
                    <source media="(min-width: 1920px)" srcSet="/img/line-right-1.svg" />
                    <img className="history-card-item-image-line right" src="/img/line-right-2.svg" alt="" />
                  </picture>
                  <div className="history-card-item-value _one">
                    <div className="history-card-item-value-text"><span>AI-прогноз </span>налоговых <br></br>рисков</div>
                  </div>
                  <div className="history-card-item-value _two active">
                    <div className="history-card-svg">
                      <svg viewBox="0 0 181 181">
                        <circle cx="90.0188" cy="90.0183" r="85" transform="rotate(90.0117 90.0188 90.0183)" strokeWidth="10" stroke="#B4BECA"></circle>
                      </svg>
                      <svg className="custom-circle3" viewBox="0 0 181 181">
                        <circle
                          strokeDasharray={String(534 * Number(usersReport.report.PERS_VPN) / 100) + ',534'}
                          cx="90.0188" cy="90.0183" r="85" transform="rotate(90.0117 90.0188 90.0183)"
                          strokeWidth="10"
                          stroke="#6FA598"
                        ></circle>
                      </svg>

                    </div>
                    <div className="history-card-item-value-text">Вероятность выездных проверок</div>
                    <div className="history-card-item-value-value">{usersReport.report.PERS_VPN}%</div>
                  </div>
                  <div className="history-card-item-value _three">
                    <div className="history-card-item-value-text">Наличие признаков дробления бизнеса</div>
                    <div className="history-card-item-value-value">{usersReport.report.VEROYATNOST}</div>
                  </div>
                </div>
                <div className="history-card-item-text">
                  <p>В блоке «AI-прогноз налоговых рисков» анализируются такие параметры как: обороты, активы, налоговая нагрузка, прибыльность, численность персонала, сравнение со среднеотраслевыми показателями. </p>
                  <p>Дополнительно проверяется наличие признаков дробления бизнеса через анализ аффилированных компаний.</p>
                </div>
              </div>
            </div>
            <div className="history-card-body-mobile">
              <div className="history-card-title"><span>Согласно проведённого анализа:</span></div>
              <div className="history-card-body-ul">
                <div className="history-card-body-li">
                  <img src={'img/' + imgStatus(usersReport.report.CATEGORY_PICTURE_ID) + '.svg'} />
                  <span>{usersReport.report.category_ID} категория благонадёжности</span>
                  <p>{usersReport.report.PDF_CATEGORY_FULL_TEXT}</p>
                </div>
                <div className="history-card-body-li">
                  <img src={'img/' + imgStatus(usersReport.report.TOO_YEAR_PICTURE_ID) + '.svg'} alt="" />
                  <span>Вероятность дефолта</span>
                  <p>{usersReport.report.TOO_YEAR}% — {usersReport.report.TOO_YEAR_TEXT}</p>
                </div>
                <div className="history-card-body-li">
                  <img src="img/ok.svg" alt="" />
                  <span>Допустимая сумма для заключения договора</span>
                  <p>{usersReport.report.SUMM} ₽</p>
                </div>
                <div className="history-card-body-li">
                  <img src={'img/' + imgStatus(usersReport.report.PERS_PICTURE_ID) + '.svg'} alt="" />
                  <span>Вероятность взыскания</span>
                  <p>{usersReport.report.PERS} — {usersReport.report.PERS_TEXT}</p>
                </div>
                <div className="history-card-body-li">
                  <img src={'img/' + imgStatus(usersReport.report.PERS_VPN_PICTURE) + '.svg'} alt="" />
                  <span>Вероятность выездных налоговых проверок</span>
                  <p>{usersReport.report.PERS_VPN}% — {usersReport.report.PERS_VPN_TEXT}</p>
                </div>
                <div className="history-card-body-li">
                  <img src={'img/' + imgStatus(usersReport.report.VEROYATNOSTPICTURE) + '.svg'} alt="" />
                  <span>Признаки дробления бизнеса</span>
                  <p>{usersReport.report.VEROYATNOST}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default HistoryCard