import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import Bay from './popup/Bay'
import ApiService from '../api/ApiService';
import { AuthContext } from '../context';
import BatchCard from './BatchCard';
import { IUserBatch } from '../api/models/responses';

const Application = () => {
    const token = useContext(AuthContext)

    const [fileName, setFileName] = useState<string>('')

    const [file, setFile] = useState<File | null>(null);

    const [politicCheck, setPoliticCheck] = useState<boolean>(true)

    const [userBatches, setUserBatches] = useState<IUserBatch[]>([])

    const [ok, setOk] = useState<boolean>(false)

    const input = useRef<HTMLInputElement>(null)

    async function fetchSendFile() {
        if (file && politicCheck) {
            setOk(true)
            input.current!.value = ''
            setFileName('')
            const formData = new FormData();
            formData.append('file', file);
            const response = await ApiService.batchReport(token, formData)
            console.log(response)
            setTimeout(() => {
                fetchGetUsersBathes()
            }, 10000)
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOk(false)
        const file = event.target.files![0];
        if (file) {
            setFile(file);
            console.log(file)
            setFileName(file.name);
        }
    }

    const sendFileHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        fetchSendFile()
    }

    async function fetchGetUsersBathes() {
        const response = await ApiService.usersBatches(token)
        console.log(response)
        setUserBatches(response.batches)
    }

    useEffect(() => {
        fetchGetUsersBathes()
    }, [])

    return (
        <section className="application">
            <div className="container">
                <h1>Заявка на массовую выгрузку</h1>
                <div className="application-item application-item-loading">
                    <form className="application-form page-form" action="#">
                        <div className="page-form-send">
                            <div className="application-header">
                                <h2>Массовая выгрузка</h2>
                                <p></p>
                            </div>
                            <div className="application-form-mask">
                                <div className="input">
                                    <div className="input-wrap">
                                        <div className="input-value" data-text="Загрузите xlsx. файл">
                                            {fileName ? fileName
                                                : "Загрузите .csv файл"
                                            }
                                        </div>
                                        <div className="input-error">Обязательное поле</div>
                                        <div className="input-right">
                                            <label className="input-load btn btn-gray">
                                                <input ref={input} type="file" name="file" accept=".csv" onChange={handleFileChange} />
                                                <svg>
                                                    <use xlinkHref={`/sprite.svg#load`} />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="application-form-footer">
                                    <button className="btn btn-red" type="submit" onClick={sendFileHandler}>Отправить заявку</button>
                                    <label className="politic politic_required">
                                        <input
                                            type="checkbox"
                                            name="politic"
                                            checked={politicCheck}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoliticCheck(!politicCheck)}
                                        />
                                        <span className="politic-wrap"><span className="politic-box">
                                            <svg>
                                                <use xlinkHref={`/sprite.svg#check`} />
                                            </svg>
                                        </span><span className="politic-text">Согласен и принимаю условия <a href="#" target="_blank">пользовательского соглашения</a> и ознакомлен с условиями <a href="#" target="_blank">личной оферты</a></span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {ok &&
                            <div className="page-form-ok">
                                <div className="application-header">
                                    <h2>Ваша заявка отправлена</h2>
                                    <p>В скором времени выгрузка появится. Пока ожидаете можете ознакомьтесь с актуальными пакетами проверки.</p>
                                </div>
                            </div>
                        }

                    </form>
                    <div className="application-example">
                        <div className="application-header">
                            <h2>Пример прикрепляемого файла</h2>
                            <p></p>
                        </div><img src="img/example.jpg" alt="Пример файла" />
                    </div>
                </div>
                <div className="application-item application-item-history" style={{ 'background': '#fff' }}>
                    <h2>История выгрузок</h2>
                    <div className="history-list">
                        {userBatches.map((batch) =>
                            <BatchCard key={batch.batchId}{...batch} />
                        )
                        }
                        {/* <div className="application-group">
                            <h3>22 марта</h3>
                            <div className="application-file">
                                <div className="application-file-svg">
                                    <svg>
                                        <use xlinkHref={`/sprite.svg#file`} />
                                    </svg>
                                </div>
                                <div className="application-file-body">
                                    <div className="application-file-name">Название файла.xlsx</div>
                                    <div className="application-file-size">100 КБ</div>
                                </div>
                            </div>
                            <div className="application-file">
                                <div className="application-file-svg">
                                    <svg>
                                        <use xlinkHref={`/sprite.svg#file`} />
                                    </svg>
                                </div>
                                <div className="application-file-body">
                                    <div className="application-file-name">Название файла.xlsx</div>
                                    <div className="application-file-size">100 КБ</div>
                                </div>
                            </div>
                            <div className="application-file">
                                <div className="application-file-svg">
                                    <svg>
                                        <use xlinkHref={`/sprite.svg#file`} />
                                    </svg>
                                </div>
                                <div className="application-file-body">
                                    <div className="application-file-name">Название файла.xlsx</div>
                                    <div className="application-file-size">100 КБ</div>
                                </div>
                            </div>
                        </div>
                        <div className="application-group">
                            <h3>12 марта</h3>
                            <div className="application-file">
                                <div className="application-file-svg">
                                    <svg>
                                        <use xlinkHref={`/sprite.svg#file`} />
                                    </svg>
                                </div>
                                <div className="application-file-body">
                                    <div className="application-file-name">Название файла.xlsx</div>
                                    <div className="application-file-size">100 КБ</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Bay tp={-3} />
        </section>
    )
}
export default Application