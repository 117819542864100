import { NavLink } from 'react-router-dom';
import { setActive } from '../utils/utils';

interface IProps {
    userInfo: {
        lastName: string;
        firstName: string;
    }
    client: any;
}
const Navbar = ({ userInfo, client }: IProps) => {

    const logoutHandler = () => {
        client.login({ prompt: 'login' });
    }
    
    console.log(userInfo);
    return (
        <div className="navbar popup" id="popup-navbar">
            <div className="navbar-wrapper">
                <div className="navbar-header"><a className="navbar-logo" href="/">
                    <img src="/img/logo-white.svg" alt="" />
                    <span className="navbar-logo-description"><span className="navbar-logo-name">AI-система для бизнеса</span><span className="navbar-logo-text">аналитический программный комплекс для оценки платёжеспособности юр. лиц с возможностью массовой выгрузки</span></span></a></div>
                <div className="navbar-header-popup">
                    <div className="header">
                        <div className="container">
                            <div className="header-wrapper">
                                <div className="header-logo"> <a href="/">
                                    <img src="/img/logo-white.svg" alt="" />
                                </a></div>
                                <div className="btn btn-red" data-modal="popup-bay" data-bay="data-bay" data-saving="80 000" data-requests="1 000" data-validity="12 месяцев" data-price="310 000 ₽">
                                    <svg>
                                        <use xlinkHref={`/sprite.svg#folders`} />
                                    </svg>
                                    <span>Купить пакеты проверки</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-close js-close">
                        <svg>
                            <use xlinkHref={`/sprite.svg#close`} />
                        </svg>
                    </div>
                </div>
                <div className="navbar-body">
                    <div className="navbar-nav">
                        <NavLink to='/' className={setActive}>
                            <svg>
                                <use xlinkHref={`/sprite.svg#nav-1`} />
                            </svg>
                            <span>История ваших запросов</span>
                        </NavLink>
                        <NavLink className={setActive} to='/page2'>
                            <svg>
                                <use xlinkHref={`/sprite.svg#nav-2`} />
                            </svg>
                            <span>Расчет категории благонадёжности</span>
                        </NavLink>
                        <NavLink className={setActive} to='/page3'>
                            <svg>
                                <use xlinkHref={`/sprite.svg#nav-3`} />
                            </svg>
                            <span>Ваш текущий пакет</span>
                        </NavLink>
                        <NavLink className={setActive} to='/page4'>
                            <svg>
                                <use xlinkHref={`/sprite.svg#nav-4`} />
                            </svg>
                            <span>Заявка на массовую выгрузку</span>
                        </NavLink>
                        <NavLink className={setActive} to='/page5'>
                            <svg>
                                <use xlinkHref={`/sprite.svg#nav-5`} />
                            </svg>
                            <span>Настройки и смена пароля</span>
                        </NavLink>
                        {/* <NavLink className={setActive} to='/page6'>
                            <svg>
                                <use xlinkHref={`/sprite.svg#nav-6`} />
                            </svg>
                            <span>Обращение в поддержку</span>
                        </NavLink> */}
                    </div>
                    <div className="navbar-footer">
                        <div className="btn btn-white">
                            <svg>
                                <use xlinkHref={`/sprite.svg#user`} />
                            </svg>
                            {userInfo && <span>{userInfo.firstName} {userInfo.lastName}</span>}
                        </div>
                        <div
                            className="btn btn-white-border js-close"
                            onClick={logoutHandler}
                        >Выйти</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar