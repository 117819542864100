import { FC, useContext, useState } from "react"
import { IUserBatch } from "../api/models/responses"
import { makeState } from "../utils/utils"
import ApiService from "../api/ApiService"
import { AuthContext } from "../context"

const BatchCard: FC<IUserBatch> = (userBatch) => {
    const token = useContext(AuthContext)

    const [loading, setLoading] = useState(false);

    async function fetchGetFile() {
        const response = await ApiService.getBatch(token, userBatch.batchId)
        console.log(response)

        const url = window.URL.createObjectURL(new Blob([response!.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const buttonHandler = (e: any) => {
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
        fetchGetFile()
    }
    return (
        <div className="history-card">
            <div className="history-card-header">
                <div className="history-card-info">
                    <div className="history-card-number">
                        {/* <div className="history-card-id">44444444</div> */}
                        {userBatch.batchState === 'FINISHED' ?
                            <div className="history-card-status" >{makeState(userBatch.batchState)}</div>
                            : userBatch.batchState === 'FAILED' ?
                                <div className="history-card-status" style={{ 'background': '#B64955' }}>Ошибка</div>
                                : <div className="history-card-status" style={{ 'background': 'rgb(223, 155, 54)' }}>В процессе</div>
                        }
                    </div>
                    <div className="history-card-text">Массовая выгрузка: {userBatch.fileName}  </div>

                    <div className="history-card-text">Кол-во инн: {Number(userBatch.billedLines)}</div>
                    <div className="history-card-text">Кол-во ошибок: {Number(userBatch.errorLines)}</div>

                    <div className="history-card-date">от {userBatch.date}</div>
                </div>
                {<div className="history-card-buttons">
                    <div className="history-card-watch">
                        {!userBatch.hasException && <div className="watch-error" data-modal="popup-error">Просмотреть ошибки</div>}
                    </div>
                    <a
                        className={`btn btn-red-border ${loading ? "btn-loading" : ""}`}
                        target="_blank"
                        download="report.pdf"
                        // style={{ 'maxWidth': '437px', 'margin': '15px 0 15px 0' }}
                        onClick={buttonHandler}
                    ><span className="btn-text">Скачать</span></a>

                </div>}


                <div className="popup" id="popup-error">
                    <div className="popup-error">
                        <div className="popup-close popup-close_white js-close">
                            <svg>
                                <use xlinkHref="/sprite.svg#close"></use>
                            </svg>
                        </div>
                        <h2>Ошибки:</h2>
                        {userBatch.hasException && userBatch.errorMessage}

                    </div>
                </div>
            </div>
        </div>
    )
}
export default BatchCard