import axios from "axios"
import { ICompanyInfo } from "./models/responses"
import { makeParamsReport } from "../utils/utils"

export default class ApiService {

    static async validateUser(token: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/validate-user',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            return response.status
        } catch (e) {
            console.log(typeof (e))
        }
    }

    static async usersReports(token: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/my-reports',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            return response.data
        } catch (e) {
            console.log(typeof (e))
        }
    }

    static async sendSupportMessage(token: string, message: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/fake',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            return response.data
        } catch (e) {
            console.log(typeof (e))
        }
    }

    static async tariffPlans(token: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/tariff-plans',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            return response.data
        } catch (e) {
            console.log(typeof (e))
        }
    }

    static async purchase(token: string, tp: Number) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/purchase',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        'tp': tp
                    },
                    validateStatus:
                        function (status) {
                            return status >= 200 && status <= 302
                        }
                })
            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async purchaseCompany(token: string, tp: Number) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/purchase-ul',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        'tp': tp
                    },
                    // validateStatus:
                    //     function (status) {
                    //         return status >= 200 && status <= 302
                    //     }
                })
            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async userInfo(token: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/my-info',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            return response.data
        } catch (e) {
            console.log(typeof (e))
        }
    }

    static async sheduleReportCategory(token: string, inn: string, dateString: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/schedule-report-category',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: makeParamsReport(inn, dateString)
                })
            return response
        } catch (e) {
            console.log(typeof (e))
        }
    }

    static async saveCompany(token: string, companyInfo: ICompanyInfo) {
        try {
            const response = await axios.post('https://api.ur-li.ru/api/client/v1/lk/save-company', {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: companyInfo
                    ,
                })
            return response
        } catch (e) {
            console.log(e)
        }
    }

    static async batchReport(token: string, file: any) {
        try {
            const response = await axios.post('https://api.ur-li.ru/api/client/v1/lk/schedule-batch-report', file ,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
            return response
        } catch (e) {
            console.log(e)
        }
    }

    static async usersBatches(token: string) {
        try {
            const response = await axios.get('https://api.ur-li.ru/api/client/v1/lk/get-batches',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            return response.data
        } catch (e) {
            console.log(typeof (e))
        }
    }
    static async getBatch(token: string, batchId: string) {
        try {
            const response = await axios.get(`https://api.ur-li.ru/api/client/v1/lk/get-batch/${batchId}`,
                {responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },

                })
            return response
        } catch (e) {
            console.log(typeof (e))
        }
    }
}