import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context'
import { IUsersReport } from '../api/models/responses'
import ApiService from '../api/ApiService'
import HistoryCard from '../components/HistoryCard'
import Bay from '../components/popup/Bay'
import { useLocation } from 'react-router'
import { getCurrentDate } from '../utils/utils'

function Page1() {
  const [usersReports, setUsersReports] = useState<IUsersReport[]>([])

  const location = useLocation()

  const token = useContext(AuthContext)

  async function fetchGetUsersReports() {
    const response = await ApiService.usersReports(token)
    console.log(response)
    setUsersReports(response.reverse())
  }

  useEffect(() => {
    fetchGetUsersReports()
    
    if (location.state?.method) {
      const p = ({ 'requestState': 'В процессе', 'id':'34-33-43', report: { 'INN': (location.state!.method as string), 'CURRENT_DATE': getCurrentDate() } } as IUsersReport)
      console.log('navigated')
      setTimeout(() => {
        setUsersReports(usersReports => [p, ...usersReports])
      }, 1000)
      setTimeout(() => {
        fetchGetUsersReports()
      }, 60000)
    }
  }, [])
  
  return (
    <section className="history">
      <div className="container">
        <h1>История запросов</h1>
        <div className="history-list">
          {usersReports.map((usersReport) =>
            <HistoryCard key={usersReport.id}{...usersReport} />
          )
          }
        </div>
      </div>
      <Bay tp={-3} />
    </section>
  )
}

export default Page1