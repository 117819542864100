import React, { useState, useEffect, useRef } from "react";
import Keycloak from "keycloak-js";

const client = new Keycloak({
    url: process.env.REACT_APP_URL,
    realm: process.env.REACT_APP_REALM!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
});

const useAuth = () => {
    const isRun = useRef(false);
    const [token, setToken] = useState<string | null>(null);
    const [isLogin, setLogin] = useState(false);
    const [userInfo, setUserInfo] = useState<any>(null);
    
    useEffect(() => {
        if (isRun.current) return;

        isRun.current = true;

        client.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
        }).then(authenticated => {
            if (authenticated) {
                setLogin(true);
                setToken(client.token || null);
                client.loadUserProfile().then(profile => {
                    setUserInfo(profile);
                });

                const updateTokenInterval = setInterval(() => {
                    client.updateToken().then(refreshed => {
                        if (refreshed) {
                            console.log('Token was successfully refreshed');
                            setToken(client.token || null);
                        } else {
                            console.warn('Token is still valid');
                        }
                    }).catch(() => {
                        console.error('Failed to refresh token');
                        setLogin(false);
                        setToken(null);
                    });
                }, 145000);

                return () => clearInterval(updateTokenInterval);
            } else {
                setLogin(false);
            }
        }).catch(error => {
            console.error('Failed to initialize Keycloak', error);
            setLogin(false);
        });
    }, []);

    return [isLogin, token, userInfo, client];
};


export default useAuth;